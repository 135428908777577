/* eslint-disable */
import { useState, useRef, useEffect } from 'react';
// import PageTitleWrapper from 'src/components/PageTitleWrapper';
// import { Helmet } from 'react-helmet-async';
// import Footer from 'src/components/Footer';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin, { DayGridView } from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction';
// import listPlugin from '@fullcalendar/list';
import { useNavigate } from "react-router-dom";
import certifyAxios from 'src/utils/aimAxios';
import moment from 'moment';
import GroupIcon from '@mui/icons-material/Group';
import {
  Grid,
  Drawer,
  Box,
  Card,
  Divider,
  useMediaQuery,
  styled,
  useTheme,
  Typography,
  Dialog,
  DialogContent,
  CircularProgress
} from '@mui/material';

import { useDispatch, useSelector } from 'src/store';
import {
  getEvents,
  updateEvent,
  selectEvent,
  selectRange,
  openDrawerPanel,
  closeDrawerPanel
} from 'src/slices/calendar';
import PageHeader from './PageHeader';

import Actions from './Actions';
import EventDrawer from './EventDrawer';

const BootstrapDialog = styled(Dialog)(() => ({
  // '& .MuiDialogContent-root': {
  //   padding: theme.spacing(2),
  // },
  // '& .MuiDialogActions-root': {
  //   padding: theme.spacing(1),
  // },
  "& .MuiDialog-container": {
      alignItems: "center"
    }
}));

const FullCalendarWrapper = styled(Box)(
  ({ theme }) => `
    // padding: ${theme.spacing(3)};
    & .fc-license-message {
      display: none;
    }

      .fc-button-primary{
        background-color:transparent !important;
        border-color:transparent !important;
        color:black !important;
        border: none !important;
      }

        .fc-button-primary:focus {
          outline: none !important;
          outline-offset: none !important;
          box-shadow: none !important;
      }

      .fc-header-toolbar{
        background-color:transparent;
        margin-top:5px !important;
        position:absolute !important;
        z-index:2;
        width:96% !important;
        // padding-right:2% !important;
        overflow-y: hidden; // hide vertical
        overflow-x: hidden; // hide horizonta
      }

      .fc th{
      border: none;
      border-bottom: 1px solid #ECECEC;
      background-color:#ECECEC;
      border-radius: 8px 8px 0px 0px; 
      overflow-y: hidden; // hide vertical
        overflow-x: hidden; // hide horizonta
      }

      /*Quitar bordes*/
      // .fc td, .fc th {
      //   border-style: none !important;
      // }
        .fc td {
        background-color: white !important;
          border-radius: 0px 0px 8px 8px; 
          border-style: none !important
        }

        .fc-scrollgrid {
        border: none !important;
      }

      // .fc-scrollgrid td:last-of-type {
      //   border-right: none !important;
      // }
        /*Fin de quitar bordes*/
    .fc {
      .fc-day-today {
        background-color: inherit !important;
        font-weight:bolder !important;
        // font-size: 16px;
      }
      .fc-col-header-cell {
        text-transform: uppercase !important;
        font-weight:lighter;
        padding: ${theme.spacing(1)};
        overflow-y: hidden; // hide vertical
        overflow-x: hidden; // hide horizonta
        // background: ${theme.colors.alpha.black[5]};
      }


    .fc-event-main{
     background-color: transparent;
    }

      // .fc-event{
      //   width: 98px !important;}
    .fc-view {
    background-color: transparent;
      .fc-event { 
        width: 94%;
        float: none;
        // margin: 0 auto;
        // padding-top:3%;
        margin-left:2%;
        // margin-right:2% !important;
        margin-top: 5%;
        border-radius:10px;
        // padding-left:;
    }
}
      
`
);

const selectedEventSelector = (state) => {
  const { events, selectedEventId } = state.calendar;

  if (selectedEventId) {
    return events.find((_event) => _event.id === selectedEventId);
  }
  return null;
};

function ApplicationsCalendar({altura}) {
  const theme = useTheme();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [bloqueo,setBloqueo] = useState(false);
  const calendarRef = useRef(null);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  // const { events, isDrawerOpen, selectedRange } = useSelector(
  //   (state) => state.calendar
  // );
  const [events,setEvents] = useState('');
  //const selectedEvent = useSelector(selectedEventSelector);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState('dayGridWeek');

  // const handleDateToday = () => {
  //   const calItem = calendarRef.current;

  //   if (calItem) {
  //     const calApi = calItem.getApi();

  //     calApi.today();
  //     setDate(calApi.getDate());
  //   }
  // };

  // const changeView = (changedView) => {
  //   const calItem = calendarRef.current;

  //   if (calItem) {
  //     const calApi = calItem.getApi();
  //     console.log(changedView);
  //     calApi.changeView(changedView);
  //     setView(changedView);
  //   }
  // };

  const handleDatePrev = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.prev();
      // setDate(calApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.next();
      // setDate(calApi.getDate());
    }
  };

  // const handleAddClick = () => {
  //   dispatch(openDrawerPanel());
  // };

  // const handleRangeSelect = (arg) => {
  //   const calItem = calendarRef.current;

  //   if (calItem) {
  //     const calApi = calItem.getApi();

  //     calApi.unselect();
  //   }

  //   dispatch(selectRange(arg.start, arg.end));
  // };

  // const handleEventSelect = (arg) => {
  //   dispatch(selectEvent(arg.event.id));
  // };

  // const handleEventResize = async ({ event }) => {
  //   try {
  //     await dispatch(
  //       updateEvent(event.id, {
  //         allDay: event.allDay,
  //         start: event.start,
  //         end: event.end
  //       })
  //     );
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  let diax  = new Date();
  diax.setHours(0,0,0,0);
  const [dia,setDia] = useState(diax);

 

  const getClasses = async (dx) =>{

    const reqObj = {
      "dia":dx
    };
    // setBloqueo(true);
    let ans = [];
    const responseActive = await certifyAxios.post('/service/class/query', reqObj);
    // console.log(responseActive);
    for(let i = 0;i<responseActive.data.list.length;i++){
      for(let j=0;j<responseActive.data.list[i].sessions.length;j++){
        let x = responseActive.data.list[i].sessions[j];
        ans.push({
          "id_sesion":x.id_sesion,
          "name":x.nombre,
          "disciplineName":x.disciplina,
          "start":x.fecha_inicio,
          "duracion":0,
          "capacity":x.aforo,
          "reserves":x.ocupado,
          "instructorName":x.personal,
          "status":x.estado_clase === 'Abierta' ? 1:0,
          "color":"#F7F7F7",
          "textColor":"black"
        });
      }
    }
    
    setEvents(ans);
    // console.log(responseActive);
    // setResultados([]);
    // setResultados(responseActive.data.list);
    // setBloqueo(false);
    // console.log(responseActive);

  }


  useEffect(() => {

    setBloqueo(true);
    getClasses(dia);
    setBloqueo(false);
    // getPlans();
      // setEvents([
      //   {
      //     "name": "RIDE CESAR R.",
      //     "disciplineName": "CYCLING CHACARILLA",
      //     "start": "2024-09-30T06:00:00-05:00",
      //     "duracion": 50,
      //     "capacity": 30,
      //     "reserves": 1,
      //     "instructorName": "CESAR R.",
      //     "status": 1,
      //     "color":"#F7F7F7",
      //     "textColor":"black"
      //   },

      // ]);
  }, []);
  
  const goClase = (clase)=>{
    // console.log(clase);
    let id = clase.id_sesion;
    navigate(`/reserve/${id}`);
  }


  const renderEventContent = (eventInfo) => {
    //console.log(eventInfo.event._def.extendedProps)
    // console.log(eventInfo.event._instance.range.start)
    return (
      // ,boxShadow:'0 0 5px 0px black'
           <div style={{height:'170px',borderRadius:8,border:0,
            paddingTop:'5%',boxShadow:'0 0 2px 0px black',
            cursor: eventInfo.event._def.extendedProps.status === 1 ? 'pointer':''
           }}
           onClick={()=>{
             if(eventInfo.event._def.extendedProps.status === 1)
              {
                goClase(eventInfo.event._def.extendedProps);
              }
           }}
           >
            {eventInfo.event._def.extendedProps.status == 1 ? (
              <>
                  <Typography noWrap style={{fontSize:'14px',fontWeight:'bold'}}>
                  &nbsp;{eventInfo.event._def.extendedProps.name}
                  </Typography>
                  <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter'}}>
                  &nbsp;{eventInfo.event._def.extendedProps.disciplineName}
                  </Typography>
                  <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter'}}>
                  &nbsp;{eventInfo.event._def.extendedProps.instructorName
                  }
                  </Typography>
                  <div style={{position:'absolute',bottom:7,width:'100%'}}>
                  <Typography noWrap style={{fontSize:'13px',fontWeight:'bold'}}>
                  &nbsp;{moment(eventInfo.event._instance.range.start).utc().format('hh:mm A')
                  }
                  </Typography>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                  <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter'}}>
                  &nbsp;
                  </Typography >
                     <div style={{justifyContent:'flex-end',display:'flex'}}>
                         <GroupIcon style = {{transform: 'rotateY(180deg)'}}/>&nbsp;
                         <Typography noWrap style={{fontSize:'14px',fontWeight:'lighter'}}>
                         {eventInfo.event._def.extendedProps.reserves}/
                         {eventInfo.event._def.extendedProps.capacity} &nbsp;
                         </Typography>
                     </div>
                  </div>
                  </div>
            </>
            ):(
            <>
              <Typography noWrap style={{fontSize:'14px',fontWeight:'bold',color:'#9B9B9B'}}>
              &nbsp;{eventInfo.event._def.extendedProps.name}
              </Typography>
              <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter',color:'#9B9B9B'}}>
              &nbsp;{eventInfo.event._def.extendedProps.disciplineName}
              </Typography>
              <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter',color:'#9B9B9B'}}>
              &nbsp;{eventInfo.event._def.extendedProps.instructorName
              }
              </Typography>
              <div style={{position:'absolute',bottom:7,width:'100%'}}>
              <Typography noWrap style={{fontSize:'13px',fontWeight:'bold',color:'#9B9B9B'}}>
              &nbsp;{moment(eventInfo.event._instance.range.start).utc().format('hh:mm A')
              }
              </Typography>
              <div style={{display:'flex',justifyContent:'space-between'}}>
              <Typography noWrap style={{fontSize:'13px',fontWeight:'lighter',color:'#9B9B9B'}}>
              &nbsp;
              </Typography >
                 {/* <div style={{justifyContent:'flex-end',display:'flex'}}>
                     <GroupIcon style = {{transform: 'rotateY(180deg)'}}/>&nbsp;
                     <Typography style={{fontSize:'14px',fontWeight:'lighter'}}>
                     {eventInfo.event._def.extendedProps.reserves}/
                     {eventInfo.event._def.extendedProps.capacity} &nbsp;
                     </Typography>
                 </div> */}
              </div>
              </div>
        </>)}
           
           </div>
    )
};
  return (
    <>
    <BootstrapDialog
        maxWidth="xs"
        fullWidth
        open={bloqueo}
        // onClose={handleCloseConfirmacion}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <DialogContent >
          <Box style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
            <CircularProgress color="primary" size="6rem"/>
          </Box>
        </DialogContent>
      </BootstrapDialog>
     <FullCalendarWrapper style={{paddingLeft:'2%',paddingRight:'2%',backgroundColor:'transparent'}}>
              <FullCalendar
                // headerToolbar={ (Typography>XD</Typography>)}
                firstDay={1}
                allDayMaintainDuration
                initialDate={date}
                initialView={view}
                locale='es'
                allDaySlot= {false}
                slotMinTime= '00:00:00'
                slotMaxTime= '24:00:00'
                // droppable
                // editable
                // nowIndicator={false}
                eventDisplay="block"
                eventContent={renderEventContent}
                // eventClick={handleEventSelect}
                // eventDrop={handleEventDrop}
                dayMaxEventRows={30}
                eventResizableFromStart
                // eventResize={handleEventResize}
                events={events}
                customButtons={{
                  nextButton: {
                      text: '',
                      icon:'chevron-right',
                      click: function() {
                          // alert('clicked the custom button!');
                          handleDateNext();
                          setBloqueo(true);
                          let x = dia;
                          x.setDate(x.getDate()+7);
                          getClasses(x);
                          setDia(x);
                          setBloqueo(false);
                      },
                  },
                  prevButton: {
                    text: '',
                    icon:'chevron-left',
                    click: function() {
                        // alert('clicked the custom button!');
                        handleDatePrev();
                        setBloqueo(true);
                        let x = dia;
                        x.setDate(x.getDate()-7);
                        getClasses(x);
                        setDia(x);
                        setBloqueo(false);
                    },
                },
              }}
                headerToolbar={{start:'prevButton',center:'',end:'nextButton'}}

                // headerToolbar={true}
                height={altura === "alta" ? 1000:700}
                // contentHeight={'auto'}
                ref={calendarRef}
                rerenderDelay={10}
                // select={handleRangeSelect}
                // selectable
                weekends
                displayEventTime='false'
                slotDuration={'24:00:00'}
                // slotMinWidth= {30}
                
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  // interactionPlugin,
                  // listPlugin
                ]}
              />
     </FullCalendarWrapper>
          
    </>
  );
}

export default ApplicationsCalendar;
